import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions";
import Connector from "./WorkganizerConnector";
import { parseJwt } from "./JwtParser";

class AuthenticationCheck extends Component<any, any> {
  async componentDidMount() {
    // console.clear();
    console.log(
      "_auth",
      JSON.parse(localStorage.getItem("persistantState") || "{}")
    );
    let _SessionKey = this.props.SeassionKey;
    console.log("_SessionKey = " + _SessionKey);
    if (_SessionKey != "") {
      const decodedToken = parseJwt(_SessionKey);
      console.log("decodedToken", decodedToken);
      if (decodedToken != null) {
        if (decodedToken.exp * 1000 < Date.now()) {
          await Connector.GetRefreshToken(this.props.RefreshToken).then(
            (data) => {
              if (data.success) {
                this.props.RequestToken(
                  data.result.accessToken,
                  data.result.refreshToken
                );
              } else {
                console.error("error", data);
                this.props.LogOut();
              }
            }
          );
        }
      }
    } else {
      this.props.LogOut();
    }
  }
  render() {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    SeassionKey: state.SeassionKey,
    RefreshToken: state.RefreshToken,
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SetSeassion: (key, refreshToken, userProfile, rememberMe) => {
      dispatch({
        type: actionTypes.SET_SESSION_KEY,
        SeassionKey: key,
        RefreshToken: refreshToken,
        UserProfile: userProfile,
        RememberMe: rememberMe,
      });
    },
    RequestToken: (key, refreshToken) =>
      dispatch({
        type: actionTypes.REFRESH_TOKEN,
        SeassionKey: key,
        RefreshToken: refreshToken,
      }),
    LogOut: () => dispatch({ type: actionTypes.CLEAR_SESSION_KEY }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationCheck);
