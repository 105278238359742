export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const SET_SESSION_KEY="SET_SESSION_KEY";
export const CLEAR_SESSION_KEY="CLEAR_SESSION_KEY";
export const SET_MESSAGE="SET_MESSAGE";
export const REDIRECT = "REDIRECT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
//export const LOG_OUT="LOG_OUT"