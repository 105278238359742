import React, { Component, Suspense } from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faSlash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import "../assets/scss/custom.scss";
import { connect } from "react-redux";
import * as actionTypes from "../store/actions";
import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import SignIn from "./PageComponents/SignIn/SignIn";
import Connector from "./Utility/WorkganizerConnector";
import { parseJwt } from "./Utility/JwtParser.tsx";
import LoaderGif from "./../assets/images/workganizerloader.gif";
import AuthenticationCheck from "./Utility/AuthenticationCheck.tsx";
import { Detector } from "react-detect-offline";
import DismessableMessage from "./Utility/Messages/DismessableMessage";
const config = window["runConfig"];
const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SeassionKey: props.SeassionKey,
      UserProfile: props.UserProfile,
      isExpired: false,
      isUnAuthorized: false,
      isLoading: false,
      isOffline: false,
    };
  }
  _mounted = false;
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.SeassionKey !== this.props.SeassionKey) {
      console.log("changed");
      this.setState({ isLoading: true });
      this.checkAuth();
    }
  }
  checkAuth() {
    let _SessionKey = this.props.SeassionKey;
    let _userProfile = this.props.UserProfile;
    console.log("_userProfile", _userProfile);
    if (_SessionKey != "" && _userProfile != null) {
      this.setState({ isLoading: true });
      const decodedToken = parseJwt(_SessionKey);
      console.log("decodedToken", decodedToken);
      if (decodedToken != null) {
        console.log("index", (decodedToken.exp * 1000 - Date.now()) / 60000);
        if (decodedToken.exp * 1000 < Date.now()) {
          this.setState({
            // is token expired token, is user is unauthorized.
            isExpired: true,
            isUnAuthorized: true,
            isLoading: false,
          });
        } else {
          if (_userProfile && (_userProfile.role == 1 || _userProfile.role == 5)) {
            this.setState({
              isExpired: false,
              isUnAuthorized: false,
              isLoading: false,
            });
          } else
            this.setState({
              isExpired: false,
              isUnAuthorized: true,
              isLoading: false,
            });
        }
      } else
        this.setState({
          isExpired: true,
          isUnAuthorized: true,
          isLoading: false,
        });
    } else {
      this.setState({
        isExpired: true,
        isUnAuthorized: true,
        isLoading: false,
      });
    }
  }
  // checkToken() {
  //   const _sessionKey = this.props.SeassionKey;
  //   if (!!_sessionKey) {
  //     const decodedToken = parseJwt(_sessionKey);
  //     console.log("decodedToken", decodedToken);
  //     if (decodedToken != null) {
  //       if (decodedToken.exp * 1000 > Date.now()) {
  //         console.clear();
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     }
  //     return false;
  //   }
  //   return false;
  // }
  loader = () => {
    return (
      <div style={{ textAlign: "center", marginTop: "10em" }}>
        <img src={LoaderGif} className="loader" />
      </div>
    );
  };
  handleChangeConnection = (e) => {
    this.setState({ isOffline: !e });
  };
  closeAlert = () => {
    this.setState({ isOffline: false });
  };
  render() {
    console.log(this.state);
    if (this.state.isLoading) return this.loader();
    else
      return (
        <HashRouter basename={config.basename}>
          <Aux>
            <Detector
              onChange={this.handleChangeConnection.bind(this)}
              render={({ online }) => (
                <>
                  <Toast
                    className="bg-danger d-inline-block m-1"
                    style={{ position: "absolute", zIndex: "9999999999999999" }}
                    show={this.state.isOffline}
                  >
                    <Toast.Body className="text-white">
                      You`re currently offline
                      <FontAwesomeIcon
                        icon={faWifi}
                        style={{ marginLeft: "5px" }}
                        color="white"
                      />
                      <FontAwesomeIcon
                        icon={faSlash}
                        style={{ position: "relative", left: "-1.2em" }}
                        color="white"
                      />
                      <FontAwesomeIcon
                        icon={faWindowClose}
                        onClick={this.closeAlert.bind(this)}
                        style={{
                          position: "relative",
                          top: "3px",
                          float: "right",
                          cursor: "pointer"
                        }}
                        color="white"
                      />
                    </Toast.Body>
                  </Toast>
                </>
              )}
            />
            <AuthenticationCheck />
            <ScrollToTop>
              {this.props.SeassionKey != "" ? (
                this.state.Expired || this.state.isUnAuthorized ? (
                  <Switch>
                    <Route
                      path="/Signin"
                      render={(props) => {
                        return <SignIn {...props} />;
                      }}
                    />
                    <Redirect to="/Signin" />
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/" component={AdminLayout} />
                    <Redirect to="/" />
                  </Switch>
                )
              ) : (
                <Switch>
                  <Route
                    path="/Signin"
                    render={(props) => {
                      return <SignIn {...props} />;
                    }}
                  />
                  <Redirect to="/Signin" />
                </Switch>
              )}
            </ScrollToTop>
          </Aux>
        </HashRouter>
      );
    // return this.loader();
  }
}
const mapStateToProps = (state) => {
  return {
    SeassionKey: state.SeassionKey,
    RefreshToken: state.RefreshToken,
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SetSeassion: (key, refreshToken, userProfile, rememberMe) => {
      dispatch({
        type: actionTypes.SET_SESSION_KEY,
        RememberMe: rememberMe,
        SeassionKey: key,
        RefreshToken: refreshToken,
        UserProfile: userProfile,
      });
    },
    LogOut: () => dispatch({ type: actionTypes.CLEAR_SESSION_KEY }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
