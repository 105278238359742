import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions";
import Connector from "../../Utility/WorkganizerConnector";
import { Row, Col, Form } from "react-bootstrap";
import Messages from "../../Utility/Messages/Messages";
import { IAbpResponse } from "../../Models/IAbpResponse";
import BackgroundImageRight from "../../../assets/images/login.png";
// import BackgroundImageLeft from "../../../assets/images/loginLeft.png";
import WorkganizerImage from "../../../assets/images/WorkganizerIcon.png";
import { parseJwt } from "../../Utility/JwtParser";
class SignIn extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      SeassionKey: this.props.SeassionKey,
      uploading: false,
      email: "",
      password: "",
      rememberMe: true,
      errorMessage: "",
      hiddenPassword: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleHiddenChange = this.handleHiddenChange.bind(this);
  }
  async componentDidMount() {
    this.setState({ errorMessage: "" });
    this.checkAuth();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.SeassionKey !== this.props.SeassionKey) {
      this.setState({ SeassionKey: this.props.SeassionKey });
      this.checkAuth();
    }
  }
  checkAuth() {
    const _sessionKey = this.props.SeassionKey;
    const _refreshToken = this.props.RefreshToken;
    const _userProfile = this.props.UserProfile;
    if (_userProfile != null && _sessionKey != "") {
      const decodedToken = parseJwt(_sessionKey);
      if (decodedToken != null) {
        console.log((decodedToken.exp * 1000 - Date.now()) / 60000);
        if (decodedToken.exp * 1000 < Date.now()) {
        } else {
          if (_userProfile == null || _userProfile == undefined) {
            Connector.GetCurrentUserInfo(_sessionKey).then((userResp) => {
              console.log("DEBUG", userResp);
              if (userResp.success) {
                if (
                  userResp.result.currentEmployee.role == 1 ||
                  userResp.result.currentEmployee.role == 5
                ) {
                  this.props.SetSeassion(
                    _sessionKey,
                    _refreshToken,
                    userResp.result.currentEmployee,
                    false
                  );
                  console.log("DEBUG 2", userResp);
                  this.props.history.push("/Dashboard");
                } else {
                  this.setState({
                    uploading: false,
                    errorMessage:
                      "You're unauthorized to access Webbiner Dashboard",
                  });
                }
              } else {
                console.log(userResp.result);
                if (userResp?.error.code) {
                  if (userResp?.error.code == 404)
                    this.setState({
                      errorMessage:
                        "Sign in Failed. Please, Check Your Internet Connection",
                      uploading: false,
                    });
                  else
                    this.setState({
                      errorMessage: userResp?.error?.message,
                      uploading: false,
                    });
                } else {
                  this.setState({
                    errorMessage: userResp?.error?.message,
                    uploading: false,
                  });
                }
              }
            });
          }
          if (_userProfile?.role == 1 || _userProfile?.role == 5) {
            this.props.history.push("/Dashboard");
          }
        }
      }
    } else if (_userProfile == null && _sessionKey != "") {
      const decodedToken = parseJwt(_sessionKey);
      if (decodedToken != null) {
        console.log((decodedToken.exp * 1000 - Date.now()) / 60000);
        if (decodedToken.exp * 1000 < Date.now()) {
        } else {
          Connector.GetCurrentUserInfo(_sessionKey).then((userResp) => {
            console.log("DEBUG", userResp);
            if (userResp.success) {
              if (
                userResp.result.currentEmployee.role == 1 ||
                userResp.result.currentEmployee.role == 5
              ) {
                this.props.SetSeassion(
                  _sessionKey,
                  _refreshToken,
                  userResp.result.currentEmployee,
                  false
                );
                console.log("DEBUG 2", userResp);
                this.props.history.push("/Dashboard");
              } else {
                this.setState({
                  uploading: false,
                  errorMessage:
                    "You're unauthorized to access Webbiner Dashboard",
                });
              }
            } else {
              console.log(userResp.result);
              if (userResp?.error.code) {
                if (userResp?.error.code == 404)
                  this.setState({
                    errorMessage:
                      "Sign in Failed. Please, Check Your Internet Connection",
                    uploading: false,
                  });
                else
                  this.setState({
                    errorMessage: userResp?.error?.message,
                    uploading: false,
                  });
              } else {
                this.setState({
                  errorMessage: userResp?.error?.message,
                  uploading: false,
                });
              }
            }
          });
          if (_userProfile?.role == 1 || _userProfile?.role == 5) {
            this.props.history.push("/Dashboard");
          }
        }
      }
    }
  }
  handleMailChange(event) {
    this.setState({ errorMessage: "" });
    this.setState({ email: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ errorMessage: "" });
    this.setState({ password: event.target.value });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ errorMessage: "" });
    console.log(this.state.email);
    console.log(this.state.password);
    if (this.state.email == "" && this.state.password == "")
      this.setState({ errorMessage: "Email and Password is Required." });
    else if (this.state.email == "")
      this.setState({ errorMessage: "Email is Required." });
    else if (this.state.password == "")
      this.setState({ errorMessage: "Password is Required." });
    else {
      this.setState({ uploading: true });
      Connector.LogIn(this.state.email, this.state.password).then((res) => {
        //console.log("DEBUG",res);
        let _abpResult = res as IAbpResponse;
        console.log("DEBUG", _abpResult);
        if (res.result?.accessToken) {
          console.log("access_token", res.result.accessToken);
          Connector.GetCurrentUserInfo(res.result.accessToken).then(
            (userResp) => {
              console.log("DEBUG", userResp);
              if (userResp.success) {
                if (
                  userResp.result.currentEmployee.role == 1 ||
                  userResp.result.currentEmployee.role == 5
                ) {
                  if (this.state.rememberMe) {
                    console.log("remembered");
                    this.props.SetSeassion(
                      res.result.accessToken,
                      res.result.refreshToken,
                      userResp.result.currentEmployee,
                      false
                    );
                  }
                  this.props.history.push("/Dashboard");
                } else {
                  this.setState({
                    uploading: false,
                    errorMessage:
                      "You're unauthorized to access Webbiner Dashboard",
                  });
                }
              } else {
                console.log(userResp.result);
                if (userResp?.error.code) {
                  if (userResp?.error.code == 404)
                    this.setState({
                      errorMessage:
                        "Sign in Failed. Please, Check Your Internet Connection",
                      uploading: false,
                    });
                  else
                    this.setState({
                      errorMessage: userResp?.error?.message,
                      uploading: false,
                    });
                } else {
                  this.setState({
                    errorMessage: userResp?.error?.message,
                    uploading: false,
                  });
                }
              }
            }
          );
        } else {
          console.log(res.result);
          if (res?.error.code) {
            if (res?.error.code == 404)
              this.setState({
                errorMessage:
                  "Sign in Failed. Please, Check Your Internet Connection",
                uploading: false,
              });
            else
              this.setState({
                errorMessage: res?.error?.message,
                uploading: false,
              });
          } else {
            this.setState({
              errorMessage: res?.error?.message,
              uploading: false,
            });
          }
        }
      });
    }
  }
  handleHiddenChange(event) {
    this.setState({ hiddenPassword: !this.state.hiddenPassword });
  }
  changeRememberMe(event) {
    this.setState({ rememberMe: !this.state.rememberMe });
  }
  render() {
    let data = this.state;

    let passwordTextType = "text";
    let passwordIcon = "fa fa-eye";
    if (this.state.hiddenPassword) {
      passwordTextType = "password";
      passwordIcon = "fa fa-eye-slash";
    }
    return (
      <div
        className="LogInUI"
        style={{
          backgroundImage: `url(${BackgroundImageRight})`,
          backgroundSize: "cover",
          height: '100vh',
        }}
      >
        <div
          className="container right-form "
          id="loginContent"
          style={{ width: "600px", fontSize: "medium" }}
        >
          {/* <img className="second-img" src={BackgroundImageLeft} /> */}
          {/* <div className="css-typing">
            <p>There is no {'"Time Back"'} button.</p>
            <p>But Workganizer has</p>
            <p>{'"Time Save"'} button.</p>
          </div> */}
          <Row className="full-hight  ">
            <Col className="middle-item">
              <div
                className="animate form login_form "
                style={{ width: "100%" }}
              >
                <div className="fadeInDown">
                  <div className="fadeIn first logo-comp">
                    <img src={WorkganizerImage} id="icon" alt="User Icon" />
                  </div>
                  <div id="formContent">
                    <form onSubmit={this.handleSubmit}>
                      <h2> Login </h2>
{/* 
                      <Row>
                        <Col md={12}>
                          <div className="pass-cont">
                            <span
                              className=""
                              //onClick={"loginFunction()"}
                              style={{ cursor: "pointer" }}
                            ></span>
                          </div>
                        </Col>
                      </Row> */}
                      <div className="remamber">
                        <input
                          type="email"
                          id="Email"
                          className="fadeIn second"
                          name="login"
                          placeholder="Enter Username/Email"
                          value={data.email}
                          onChange={this.handleMailChange}
                        />
                        <div>
                          <input
                            type={passwordTextType}
                            id="password"
                            name="login"
                            className="fadeIn third"
                            placeholder="Password"
                            value={data.password}
                            onChange={this.handlePasswordChange}
                          />
                          <span className="hidePassword">
                            <i
                              className={passwordIcon}
                              aria-hidden="true"
                              style={{ cursor: "pointer" }}
                              onClick={this.handleHiddenChange}
                            ></i>
                          </span>
                        </div>
                        <div>
                          <Form.Check
                            type={"checkbox"}
                            checked={this.state.rememberMe}
                            id="rememeberMe"
                            onChange={this.changeRememberMe.bind(this)}
                            label="Remember Me"
                          />
                        </div>
                      </div>

                      {data.uploading == false && (
                        <input
                          type="submit"
                          value="Login"
                          className="btn btn-default submit"
                          style={{ fontSize: "1.5rem", cursor: "pointer" }}
                        />
                      )}

                      {data.uploading && (
                        <input
                          type="submit"
                          value="Logging in..."
                          className="btn btn-default submit"
                          style={{ fontSize: "1.5rem", cursor: "pointer" }}
                          disabled
                        />
                      )}

                      <br />
                      <Row>
                        <Col md={12} id="emailField" style={{ color: "red" }}>
                          {this.state.errorMessage}
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    SeassionKey: state.SeassionKey,
    RefreshToken: state.RefreshToken,
    UserProfile: state.UserProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SetSeassion: (key, refreshToken, userProfile, rememberMe) => {
      dispatch({
        type: actionTypes.SET_SESSION_KEY,
        RememberMe: rememberMe,
        SeassionKey: key,
        RefreshToken: refreshToken,
        UserProfile: userProfile,
        // UserName: UserName,
        // UserImageUrl: UserImageUrl,
      });
    },
    SetMessage: (message) => {
      dispatch({
        type: actionTypes.SET_MESSAGE,
        Message: message,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
